
.top-nav {
    margin: 20px 0 80px 20px;
}

.logo {
    display: inline;
}

.logo:hover {
    color: lightgreen;
}

.menu-links {
    margin-top: 20px;
    margin-left: 50px;
}

.menu-link {
    margin-bottom: 20px;
}

.menu-link:hover {
    color: lightgreen;
}