@media screen and (max-width: 600px){
  .story-full {
    margin: 40px 10px 0 10px;
    line-height: 1.5;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
    .story-full {
      margin: 40px 100px 0 100px;
      line-height: 1.5;
    }
  }
  
  @media screen and (min-width: 1000px){
    .story-full {
      margin: 40px 200px 0 200px;
      line-height: 1.5;
    }
  }