
.center-text {
    text-align: center;
  }

.projects-header {
    margin-bottom: 40px;
}

@media screen and (min-width: 800px) {
  .column {
      float: left;
      width: calc(100% / 2);
      height: 600px;
  }
}

@media screen and (max-width: 600px) {
  .column {
    padding: 0 5px 0 5px;
  }
}

.column-content {
  padding: 0 40px 0 40px;
}

.image {
  width: 100%;
}