
.quote-item {
    font-size: 15px;
    list-style: none;
    color: black;
}

.quote {
    display: block;
    display: inline;
}

.quote-text {
    font-style: italic;
    display: inline;
}

.quote-author {
    display: inline;
}
