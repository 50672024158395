
.book-list {
    display: inline;
    margin: 0 0 10px 20px;
    font-size: 15px;
    line-height: 30px;
    list-style: none;
    color: black;
}

.book:link, .book:visited {
    color: black;
    text-decoration: none;
}

.book:hover {
    color: lightgreen;
    text-decoration: none;
}