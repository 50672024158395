
.top-nav {
    margin: 20px 0 80px 20px;
}

.top-nav-links {
    display: inline;
}

.top-nav-link {
    padding-left: 20px;
    display: inline;
}

.top-nav-link:hover {
    color: lightgreen;
}

.logo {
    display: inline;
}

.hamburger-menu {
    display: none !important;
    margin-right: 10px;
}

@media screen and (max-width: 600px) {
    .top-nav-links {
      display: block;
    }
    .hamburger-menu {
        display: inline !important;
        float: right !important;
    }
    .top-nav-links {
        display: none;
    }
    .logo {
      font-size: 25px;
    }
}

.logo:hover {
    color: lightgreen;
}

