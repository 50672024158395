
.home-profile-image {
    text-align: center;
    position: relative;
    margin-top: 100px;
}

.nav-links {
    text-align: center;
    position: relative;
    margin-top: 20px;
}

.nav-link {
    margin-bottom: 20px;
}

.nav-links-wrapper {
    text-align: center;
    position: relative;
}

.nav-link:hover {
    color: lightgreen;
}

.brackets {
    text-align: center;
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.end-link {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.end-link a:link, a:visited {
    color: white;
}