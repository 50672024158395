
.images {
    height: auto;
    max-width: 750px;
    padding: 0 2px 0 2px;
}

@media screen and (max-width: 600px) {
    .images {
      max-width: 500px;
    }
}

@media screen and (max-width: 500px) {
    .images {
      max-width: 400px;
    }
}