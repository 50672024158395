.image_profile {
    height: 100px;
    width: 100px;
    margin: 2px;
    border-radius: 50%;
}

@media screen and (max-width: 600px) {
    .image_profile {
      max-width: 500px;
    }
}

@media screen and (max-width: 500px) {
    .image_profile {
      max-width: 400px;
    }
}