
.story {
    display: inline;
    margin: 0 0 10px 20px;
    font-size: 15px;
    line-height: 30px;
    list-style: none;
}

.story:hover {
    color: lightgreen;
}