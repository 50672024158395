
.listenings-footer {
    text-align: center;
}

.listenings-footer a:link, .listenings-footer a:visited {
    color: black;
    text-decoration: none;
}

.listenings-footer a:hover {
    color: lightgreen;
    text-decoration: none;
}