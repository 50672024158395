
.column-header {
    text-align: center;
}

@media screen and (min-width: 600px) {
    .left-column {
        float: left;
        width: 30%;
    }

    .center-column {
        float: left;
        width: 40%;
        text-align: center;
    }

    .right-column {
        float: left;
        text-align: center;
        width: 30%;
    }
}

@media screen and (max-width: 600px) {
    .left-column {
        padding: 0 5px 0 5px;
    }

    .center-column {
        padding: 0 5px 0 5px;
        text-align: center;
    }

    .right-column {
        padding: 0 5px 0 5px;
    }

    .box-style {
        border: 3px solid black;
        padding: 50px 0 50px 0;
        margin: 0;
    }
}

.box-style {
    border: 3px solid black;
    padding: 50px 0 50px 0;
    margin: 0 125px 0 125px;
}

.list-position {
    display: table;
    margin: auto;
}

@media screen and (min-width: 600px) and (max-width: 800px){
    .box-style {
        margin: 0 25px 0 25px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1000px){
    .box-style {
        margin: 0 50px 0 50px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
    .box-style {
        margin: 0 75px 0 75px;
    }
}