
.list-style {
    list-style: none;
    line-height: 25px;
    text-align: center;
    padding: 0;
}

.link-style {
    text-decoration: none;
    color: black;
}

.link-style:hover {
    color: lightgreen;
}